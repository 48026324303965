import Styles from "@/components/Blog/Pagination.module.scss"
import arrowLeft from "@/public/icons/arrow-left.svg"
import arrowRight from "@/public/icons/arrow-right.svg"

import { useEffect, useState } from "react"

const Pagination = props => {
  const { activePage, setActivePage, numberOfPages } = props
  const [pageNumbers, setPageNumbers] = useState([])
  useEffect(() => {
    let pages = []
    for (let i = 1; i <= numberOfPages; i++) {
      pages = [...pages, i]
    }
    setPageNumbers(pages)
  }, [numberOfPages])
  const disabledNext = !(activePage < numberOfPages)
  const disabledPrevious = !(activePage > 1)
  const scrollToTopOfPage = () => {
    const element = document.getElementById("blog-page__tab-list")
    if (element) {
      setTimeout(function () {
        window.scrollTo({ top: element.offsetTop - 50, behavior: "smooth" })
      }, 2)
    }
  }
  return (
    <div className={Styles.pagination}>
      <div className="page-item">
        <button
          onClick={() => {
            setActivePage(activePage - 1)
            scrollToTopOfPage()
          }}
          className={`page-item__arrow-icon ${
            disabledPrevious ? "disabled" : ""
          }`}
          disabled={disabledPrevious}
        >
          <img src={arrowLeft.src} alt={"Arrow Left"} />
        </button>
        {pageNumbers.map(pageNumber => (
          <button
            key={pageNumber}
            onClick={() => {
              setActivePage(pageNumber)
              scrollToTopOfPage()
            }}
            className={`page-item__pageNumbers ${
              activePage === pageNumber ? "page-item__active" : ""
            }`}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={() => {
            setActivePage(activePage + 1)
            scrollToTopOfPage()
          }}
          className={`page-item__arrow-icon ${disabledNext ? "disabled" : ""}`}
          disabled={disabledNext}
        >
          <img src={arrowRight.src} alt={"Arrow Right"} />
        </button>
      </div>
    </div>
  )
}
export default Pagination
