import React, { useEffect, useState } from "react"
import useWindowResize from "@/hooks/useWindowResize"
import { removeAndCleanLinkConcat } from "@/utils/helper"

const List = ({ data = {} }) => {
  const [open, setopen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { siteMapHeading = "", options = [] } = data

  const [width] = useWindowResize()
  useEffect(() => {
    setIsMobile(width <= 991)
  }, [width])

  const handleOpen = () => {
    if (isMobile) {
      setopen(!open)
    }
  }

  return (
    <div className="list-inner">
      <h3
        className={`cmp-list__heading ${open ? "active" : ""}`}
        onClick={handleOpen}
      >
        {siteMapHeading?.replace(/<[^>]*>/g, "")}
      </h3>
      {options.map(listItem => (
        <ul
          className="cmp-list"
          style={{ display: !isMobile || open ? "block" : "none" }}
        >
          {listItem.subHeader ? (
            <li className="cmp-list__subhead">{listItem.subHeader}</li>
          ) : null}
          {listItem.subHeaderItem?.map(innerListItem => (
            <ul>
              <li className="cmp-page-name">
                <a
                  target={innerListItem.isNewTab ? "_blank" : ""}
                  rel={innerListItem.subPageLinkRef ? "noreferrer" : ""}
                  href={removeAndCleanLinkConcat(innerListItem.subPageLink)}
                >
                  {innerListItem.subDisplayName}
                </a>
              </li>
            </ul>
          ))}
        </ul>
      ))}
    </div>
  )
}

export default List
